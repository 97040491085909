.redactor-styles {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.redactor-styles ol {
    list-style-type: decimal;
}

.redactor-styles ul {
    list-style-type: disc;
}

.redactor-box.redactor-styles-on {
    border: none;
    border-radius: inherit;
    box-shadow: none;
}

.redactor-toolbar,
.redactor-air {
    a:hover {
        @apply bg-blue-500;
    }
}

.redactor-source-view {
    & .redactor-toolbar a {
        &:hover {
            @apply bg-blue-500;
        }
    }
}

.redactor-component[data-redactor-type=variable] {
    @apply bg-blue-600;
}

.redactor-component-active {
    outline: 5px solid $brand-hover !important;
}

.redactor-component-active[data-redactor-type=image] {
    & img {
        outline: 5px solid $brand-hover !important;
    }
}

.redactor-component-active[data-redactor-type=variable] {
    @apply bg-blue-500;
}

.redactor-component-active[data-redactor-type=video] {
    outline: none !important;
    & iframe {
        outline: 5px solid rgba(#007dff, .5) !important;
    }
}

.redactor-modal input[type=radio],
.redactor-modal input[type=checkbox] {
    appearance: auto;
}

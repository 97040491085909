.progressive {
  position: relative;
  display: block;
}

.progressive__img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transform: translateZ(0);
}

.progressive--not-loaded {
  filter: blur(30px);
}

.progressive--is-loaded {
  filter: blur(20px);
  animation: sharpen 0.5s both;
}

@keyframes sharpen {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0px);
  }
}

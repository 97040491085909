// Platform color palette
// -------------------------
$brand-primary: #0086ff;
$brand-light: #a4d3e7;
$brand-dark: #1e5166;
$brand-hover: #5ec1e9;
$brand-red: #df4636;

// Platform gradient colors
// -------------------------
$gradient-primary: #128eff;
$gradient-light: #2eaee3;

// Common colors
// -------------------------
$light-grey: #f0f0f3;
$lighter-grey: #f6f7f9;
$dark-grey: #9eaebe;
$text-grey: #7a7979;
$primary-border: #2691bf;
$error: #EF4444;
$warning: #f0ad4e;
$success: #10B981;

// Notifications
// -------------------------
$vt-color-default: #10B981;
$vt-color-error: #EF4444;
$vt-font-family: inherit;

// Redactor
// -------------------------
$color-link: $brand-primary;
$color-link-hover: $brand-hover;
$color-error: $error;
$color-success: $success;
$color-warning: $warning;
$color-focus: $brand-primary;
$base-font-family: inherit;

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;

  &.compact {
    .background-masker {
      &.header-top-masker,
      &.header-bottom-masker,
      &.subheader-bottom-masker {
        left: 0;
      }

      &.header-left-masker,
      &.subheader-left-masker {
        width: 0;
      }

      &.header-right-masker {
        right: 30px;
        left: 210px;
      }

      &.subheader-bottom-masker {
        right: 0;
      }

      &.content-first-end-masker {
        left: 160px;
        right: 0;
      }

      &.content-top-masker {
        right: 0;
      }

      &.content-bottom-masker {
        right: 0;
      }
    }
  }
}

.background-masker {
  background: #fff;
  position: absolute;

  &.header-top-masker,
  &.header-bottom-masker,
  &.subheader-bottom-masker {
    top: 8px;
    left: 40px;
    right: 0;
    height: 10px;
  }

  &.header-left-masker,
  &.subheader-left-masker,
  &.header-right-masker,
  &.subheader-right-masker {
    top: 0;
    left: 40px;
    height: 8px;
    width: 10px;
  }

  &.header-bottom-masker {
    top: 18px;
    height: 6px;
  }

  &.subheader-left-masker,
  &.subheader-right-masker {
    top: 24px;
    height: 6px;
  }

  &.header-right-masker,
  &.subheader-right-masker {
    width: auto;
    left: 300px;
    right: 0;
  }

  &.header-right-masker {
    right: 180px;
  }

  &.subheader-right-masker {
    left: 230px;
  }

  &.subheader-bottom-masker {
    top: 30px;
    height: 10px;
    right: 100px;
  }

  &.content-top-masker,
  &.content-bottom-masker,
  &.content-second-line-masker,
  &.content-third-line-masker,
  &.content-second-end-masker,
  &.content-third-end-masker,
  &.content-first-end-masker {
    top: 40px;
    left: 0;
    right: 0;
    height: 6px;
  }

  &.content-top-masker {
    height: 10px;
    right: 100px;
  }

  &.content-bottom-masker {
    top: 58px;
    height: 12px;
    right: 100px;
  }

  &.content-first-end-masker,
  &.content-second-end-masker,
  &.content-third-end-masker {
    width: auto;
    left: 250px;
    right: 0;
    top: 50px;
    height: 8px;
  }

  &.content-first-end-masker {
    right: 100px;
  }

  &.content-second-line-masker {
    top: 68px;
  }

  &.content-second-end-masker {
    left: 420px;
    top: 74px;
  }

  &.content-third-line-masker {
    top: 82px;
  }

  &.content-third-end-masker {
    left: 300px;
    top: 88px;
  }
}
.kanban-canvas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 -10px;

  @media (max-width: 1023px) {
    display: block;
  }

  .card-container {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 10px;
    position: relative;
    overflow: visible;

    @media (max-width: 1023px) {
      margin-bottom: 30px;
    }

    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
      overflow: visible;
    }

    .card-column-header {
      font-size: 18px;
    }

    .card-column-footer {
      @apply text-gray-400;
      font-size: 16px;
    }
  }

  .card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg)
  }

  .card-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg)
  }

  .drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
    margin: 5px;
  }

  .cards-drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
    margin: 5px 45px 5px 5px;
  }
}
